export const DATETIME_UNITS = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "quarter",
  "year",
  "hour-of-day",
  "day-of-week",
  "day-of-month",
  "week-of-year",
  "month-of-year",
  "quarter-of-year",
];
